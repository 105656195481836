<template>
  <div v-if="isAdmin || checkAccess('invoices')">
    <report-list-page
        page-title="Invoice Report"
        page-icon="transform"
        sort="invoiceNo"
        :status-arr="statusArr"
        :descending="true"
        :headers="headers"
        state-end-point="invoice.invoices"
        :actions="actions"
        :show-component="showComponent"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "InvoiceReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Invoice No',
          value: 'invoiceNo'
        },
        {
          text: 'Invoice Date',
          value: 'createdAt'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          align: 'left',
          value: 'paymentStatus'
        },
        {
          text: 'Invoice Details',
          align: 'left',
          value: 'invoiceDetails'
        },
        {
          text: 'Payment Details',
          align: 'left',
          value: 'paymentDetails'
        },
        {
          text: 'Total Amount',
          align: 'right',
          value: 'totalAmount'
        }
      ],
      actions: {
        load: 'loadInvoices',
      },
      statusArr: [
        {name: 'Unpaid', id: 100},
        {name: 'Paid', id: 200},
        {name: 'Deleted', id: 500}
      ],
      showComponent: {
        isUser: true,
        isStatus: true,
        isMethod: true,
        isEmail: true,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>